"use client";
export {
  type IconProps,
  Bird,
  Briefcase,
  Code,
  FileRs,
  Student,
  GithubLogo,
  ArticleMedium,
  Heartbeat,
  ShootingStar,
  Spinner,
} from "@phosphor-icons/react";
